.adminPanel {
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
}

.adminPanel .principalCard {
    font-size: 1.1rem;
    min-height: 52px;
    display: flex;
    align-items: center;
}

.adminPanel .principalCard[data-dirty] {
    background-color: lightyellow;
}

.adminPanel .newEntry:hover {
    background-color: lightgray;
    cursor: pointer;
}

.adminPanel .principalCard.revoked {
    background-color: lightcoral;
}

.adminPanel .namePart {
    display: inline-block;
    padding: 6px;
    width: 20%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.adminPanel .rotatePart {
    display: inline-block;
    padding: 6px;
    width: 30%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.adminPanel .emailPart {
    display: inline-block;
    padding: 6px;
    width: 30%;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.adminPanel .scopePanel {
    display: inline-block;
    width: 50%;
}

.adminPanel .savebtn {
    width: 100%;
    display: flex;
    justify-content: center;
}