.scopePanel {
    padding: 4px;
    font-size: 0.9rem;
    display: inline-block;
}

.scopePanel .scopeBadge {
    display: inline-block;
    background-position-y: 20px;
    padding: 0px 2px 3px 4px;
    border-radius: 14px;
    background-color: lightgray;
}

.scopePanel .scopeAddBadge {
    display: inline-block;
    background-position-y: 20px;
    padding: 0px 2px 1px 2px;
    margin-bottom: 2px;
    border-radius: 14px;
    background-color: lightgray;
}

.scopePanel .MuiIconButton-root{
    margin: 0px;
    padding: 0px;
}
.scopePanel .newScope {
    display: inline-block;
    padding: 1px;
    margin: -8px 2px;
    width: 100px;
}

.scopePanel .MuiAutocomplete-popper * {
    font-size: 0.80rem;
}

.scopePanel .newScope * {
    font-size: 0.80rem;
}